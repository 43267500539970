<template>
  <div class="form">
    <van-form @submit="onSubmit">
      <van-field
        v-model="username"
        name="用户名"
        label="用户名"
        placeholder="用户名"
        :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <van-field
        v-model="password"
        type="password"
        name="密码"
        label="密码"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { login, quicklogin } from "@/api/task";

function getcustomerinfo(that) {
  window.CP.getCustomerInfo({
    completed: function (data) {
      const mobile = data.mobile;
      // alert(mobile)
      quicklogin({ mobile: mobile }).then((res) => {
        if (res.code == 1) {
          localStorage.setItem("userinfo", JSON.stringify(res.data.userinfo));
          that.$toast("登录成功");
          that.$router.push({ name: "Worker" });
          // window.location.reload();
        }
        else{
          this.$toast(res.msg);
        }
      });
      // alert(JSON.stringify(data))
    },
  });
}



export default {
  name: "login",
  created() {

    const token = localStorage.getItem("token");
    if (token != null) {
      this.$router.push({ name: "Worker" });
      return;
    }

    let that=this;
    setTimeout(() => {
      const clientCode = localStorage.getItem("clientCode");
      if (clientCode == null) {
        alert("null")
      }
      if (clientCode == "xsb_iwenling") {
        window.CP.isLogin({
          completed: function (data) {
            if (data.is_login !== 1) {
              window.CP.showLogin({
                completed: function (data) {
                  if (data.is_login === 1) {
                    getcustomerinfo(that);
                  }
                },
              });
            } else {
              getcustomerinfo(that);
            }
          },
          cpIncompatible: function () {
            console.log("此环境不支持此方法");
          },
        });
      }
    }, 500);
  },
  mounted() {

  },
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    onSubmit() {
      login({ account: this.username, password: this.password }).then((res) => {
        if (res.code == 1) {
          localStorage.setItem("userinfo", JSON.stringify(res.data.userinfo));
          this.$toast("登录成功");
          this.$router.push({ name: "Worker" });
        } else {
          this.$toast(res.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.form {
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
}
</style>
